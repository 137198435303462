<template>
   <div :id="ticket.id" class="col-12">
      <div class="card cursor-pointer mb-1" :class="ticket.status +'Class'" @click="view">
         <div class="card-body p-2">
            <div class="row m-0">
               <div class="w-max-content pr-1 d-none d-lg-block">
                  <div class="circle">
                     <div class="circle-wrapper" v-if="ticket.newMessage == true"></div>
                     <img class="rounded-circle" :src="ticket.photo == null ? '' : ticket.photo" @error="imageError">
                  </div>
               </div>
               <div class="col">
                  <h6 class="limitador mb-1">{{ ticket.name == null ? 'Unknown' : ticket.name }}</h6>
                  <span class="limitador font-12 d-block">
                     <span class="color-theme mr-1">Status:</span>
                     <span v-if="ticket.status == 'New'" class="badge badge-danger"> New</span>
                     <span v-else-if="ticket.status == 'In progress'" class="badge badge-info"> In progress</span>
                     <span v-else-if="ticket.status == 'Closed'" class="badge badge-success"> Closed</span>
                     <span v-else class="badge badge-default"> Unknown</span>
                  </span>
                  <span class="limitador font-12 d-block"><span class="color-theme mr-2">Last message:</span>{{ ticket.lastMessageDate == null ? 'Unknown' : new Date(ticket.lastMessageDateString).toLocaleString() }}</span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
	name: 'Ticket',
   props: ['ticket'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      view : function () {
         this.$emit('view', this.ticket)
      }
   }
}

</script>

<style scoped>

.NewClass {
   border-left: 3px solid #f00 !important;
}

.progressClass {
   border-left: 3px solid rgb(51, 0, 255) !important;
}

.ClosedClass {
   border-left: 3px solid rgb(0, 115, 52) !important;
}

.UnknownClass {
   border-left: 3px solid #555 !important;
}

img {
   position: absolute;
   top: 2px;
   left: 2px;
   width: 56px;
   height: 56px;
   border-radius: 50%;
}

.circle {
   position: relative;
   width: 60px;
   height: 60px;
}

.circle-wrapper {
   display: block;
   width: 100%;
   height: 100%;
   border-radius: 50%;
   padding: 0px;
   background-clip: content-box;
   animation: spin 45s linear infinite;
   background-color: var(--primary-color);
   border: 2px dashed var(--primary-color);
}

.circle:active .circle-wrapper {
   animation: spin 2s linear infinite;
}

@keyframes spin { 
   100% { 
      transform: rotateZ(360deg);
   }
}

</style>