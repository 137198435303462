<template>
   <li v-if="message.sticker != true" class="clearfix" :class="message.author == (dadosUsuario.username +'#'+ dadosUsuario.discriminator) ? 'right' : 'left'">
      <img class="user_pix" v-if="message.author == (dadosUsuario.username +'#'+ dadosUsuario.discriminator)" :src="dadosUsuario.usuarioFoto == null ? '' : dadosUsuario.usuarioFoto" @error="imageError" alt="avatar">
      <img class="user_pix" v-else :src="message.photo == null ? '' : message.photo" @error="imageError" alt="avatar">
      <div class="message">
         <span class="d-block weight-900 limitador" v-if="message.author == (dadosUsuario.username +'#'+ dadosUsuario.discriminator)">
            <span class="font-11 weight-400 mr-3">{{ new Date(message.date).toLocaleString() }}</span>
            <span class="color-theme">{{ message.author }}</span>
         </span>
         <span class="d-block weight-900 limitador" v-else>
            <span>{{ message.author }}</span>
            <span class="font-11 weight-400 ml-3">{{ new Date(message.date).toLocaleString() }}</span>
         </span>
         <span class="d-block mb-2" v-html="message.message"></span>
         
         <div id="attachs" class="row" :class="message.author == (dadosUsuario.username +'#'+ dadosUsuario.discriminator) ? 'justify-content-end' : 'justify-content-start'">
            <mediaFromUrl v-for="(url, index) in message.attachs" :key="index" :url="url" />
         </div>
      </div>
   </li>
</template>

<script>

import { mapState } from 'vuex'
import mediaFromUrl from '@/components/ticket/MediaFromUrl.vue'

export default {
	name: 'Message',
   props: ['message'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario
      })
   },
   components: {
      mediaFromUrl
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   },
   mounted() {
      this.message.emojis.forEach(emoji => {
         this.message.message = String(this.message.message).replace('<:'+ emoji.name +':'+ emoji.id +'>', '<img class="w25 h25" src="https://cdn.discordapp.com/emojis/'+ emoji.id +'.png" />')
      });
   }
}

</script>