<template>
   <span class="float-left w-max-content d-flex mr-2">
      <span class="color-icon" :style="'backgroundColor: rgb('+ role.color +')'"></span><span class="limitador">{{ role.name }}</span>
   </span>
</template>

<script>

export default {
	name: 'Role',
   props: ['role']
}

</script>

<style scoped>

.w-max-content {
   min-width: max-content;
}

.color-icon {
   width: 10px;
   display: block;
   height: 10px;
   margin-top: 6px;
   border-radius: 2px;
   margin-right: 4px;
}

</style>