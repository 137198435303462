<template>
   <div class="alert alert-primary text-center w200 p-y-10 mr-1 mb-1" title="Delete" @click="remove(index)">
      <i class="far fa-trash text-danger"></i><span class="limitador">{{ file.name == null ? file.nome : file.name }}</span>
      <span class="limitador d-block font-12">{{ (file.size / 1048576) >= 1 ? (file.size / 1048576).toFixed(1) +"MB" : (file.size / 1024).toFixed(1) +"KB" }}</span>
   </div>
</template>

<script>

export default {
	name: 'Attachment',
   props: ['file', 'index'],
   methods: {
      remove : function (index) {
         this.$emit('remove', index)
      }
   }
}

</script>