<template>
   <div class="row">
      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none empty" data-toggle="dropdown" aria-expanded="false">
            <i class="far fa-search font-17"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row m-0">
                  <div class="col-12 col-sm-6 col-md-3 mb-12 px-1">
                     <label><i class="far fa-filter font-13 color-theme mr-1"></i> {{ $t("ticketSearch.search.type") }}</label>
                     <v-select class="rounded" title="Desktop" :options="['Discord', 'Status']" v-model="pesquisa.tipo" />
                     <select title="Mobile" class="form-control" v-model="pesquisa.tipo">
                        <option v-for="(option, index) in ['Discord', 'Status']" :key="index" :value="option">{{ option }}</option>
                     </select>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 mb-12 px-1" v-if="pesquisa.tipo == 'Discord'">
                     <label><i class="fab fa-discord font-13 color-theme mr-1"></i> Discord</label>
                     <input type="text" class="form-control rounded" v-model="pesquisa.discord" maxlength="200" @keyup.enter="getTickets" />
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 mb-12 px-1" v-else>
                     <label><i class="far fa-sync font-13 color-theme mr-1"></i> {{ $t("ticketSearch.search.status") }}</label>
                     <v-select class="rounded" title="Desktop" :options="$t('ticketSearch.search.statusList')" label="value" v-model="pesquisa.status" />
                     <select title="Mobile" class="form-control" v-model="pesquisa.status">
                        <option v-for="(option, index) in $t('ticketSearch.search.statusList')" :key="index" :value="option">{{ option.value }}</option>
                     </select>
                  </div>
                  <div class="col-12 col-sm-8 col-md-4 px-0">
                     <div class="row m-0">
                        <div class="col-6 mb-12 px-1">
                           <label><i class="far fa-calendar color-theme font-13 mr-1"></i> {{ $t("ticketSearch.search.initial") }}</label>
                           <datePicker class="rounded" title="Desktop" :format="'dd/MM/yyyy'" :language="ptBR" v-model="pesquisa.dataI"></datePicker>
                           <input title="Mobile" class="form-control" type="date" v-model="pesquisa.dataI" />
                        </div>
                        <div class="col-6 mb-12 px-1">
                           <label><i class="far fa-calendar color-theme font-13 mr-1"></i> {{ $t("ticketSearch.search.final") }}</label>
                           <datePicker class="rounded" title="Desktop" :format="'dd/MM/yyyy'" :language="ptBR" v-model="pesquisa.dataF"></datePicker>
                           <input title="Mobile" class="form-control" type="date" v-model="pesquisa.dataF" />
                        </div>
                     </div>
                  </div>
                  <div class="col-12 col-sm-4 col-md-1 mb-12 px-1 align-self-end">
                     <button type="button" class="btn btn-primary w-100 rounded" @click="getTickets">
                        <i class="fa fa-search font-13"></i>
                     </button>
                  </div>
                  <div class="col-12 mb-12 px-1 text-right text-secondary"><i class="far fa-exclamation-triangle font-11 mr-1"></i> {{ $t("serverSettings.updateLimit") }}</div>
               </div>
            </div>
         </div>
      </div>

      <div class="carousel">
         <div v-for="(coluna, index) in pesquisa.resultado" :key="index" class="card-drop pr-2">
            <div class="card mb-2" :class="coluna.tickets == null || coluna.tickets.length == 0 ? 'empty' : ''">
               <div class="card-body p-10">
                  <div class="header p-0">
                     <ul class="header-dropdown dropdown">
                        <li class="dropdown">
                           <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" @click="toggleDropdown"></a>
                           <ul class="dropdown-menu theme-bg gradient vivify fadeIn">
                              <li><a href="javascript:void(0);" @click="openEditColumn(coluna)"><i class="fal fa-pen font-12"></i> {{ $t("ticketSearch.buttons.edit") }}</a></li>
                              <li><a href="javascript:void(0);" @click="deleteColumn(coluna.id)" v-if="index != 0"><i class="fal fa-trash font-12"></i>{{ $t("ticketSearch.buttons.delete") }}</a></li>
                           </ul>
                        </li>
                     </ul>
                  </div>
                  <h6 class="limitador m-2 mb-3" :style="'color: '+ coluna.color"><small class="mr-1">{{ coluna.icon }}</small> {{ coluna.name }}</h6>
                  <draggable :id="coluna.id" class="row row-drop" :list="coluna.tickets" :sort="false" group="group" @end="attColumn">
                     <ticket v-for="(ticket, index) in coluna.tickets" :key="index" :ticket="ticket" @view="viewTicket($event)" />
                  </draggable>
               </div>
            </div>
         </div>
         <div class="card-drop">
            <div class="card mb-2">
               <div class="card-body cursor-pointer" @click="openAddColumn">
                  <h6 class="limitador-2 text-center my-4">
                     <i class="far fa-plus font-20 mb-2 d-block"></i>
                     <span>{{ $t("ticketSearch.column.add") }}</span>
                  </h6>
               </div>
            </div>
         </div>
      </div>

      <!-- modalColumn -->
      <div class="modal fade" id="modalColumn" tabindex="-1" aria-labelledby="modalColumnLabel" data-backdrop="static" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalColumnLabel" v-if="modalColumn != null && modalColumn.id != null">{{ $t("ticketSearch.column.edit") }}</h5>
                  <h5 class="modal-title h4" id="modalColumnLabel" v-else>{{ $t("ticketSearch.column.add") }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="card mb-0">
                     <div class="card-body">
                        <div class="row m-0">
                           <div class="col-12 px-1 mb-3">
                              <label class="mb-1"><i class="far fa-hashtag color-theme font-13 mr-1"></i> {{ $t("ticketSearch.column.name") }}</label>
                              <input type="text" class="form-control rounded" maxlength="200" v-model="modalColumn.name" />
                           </div>
                           <div class="col-12 px-1 mb-3">
                              <label class="mb-1"><i class="far fa-palette color-theme font-13 mr-1"></i> {{ $t("ticketSearch.column.color") }}</label>
                              <input type="color" class="form-control border-0 p-0 rounded" v-model="modalColumn.color">
                           </div>
                           <div class="col-12 px-1">
                              <label class="mb-1"><i class="far fa-icons color-theme font-13 mr-1"></i> {{ $t("ticketSearch.column.icon") }}</label>
                              <div class="d-flex align-items-center">
                                 <span class="d-block font-40 mb-2">{{ modalColumn.icon }}</span>
                                 <i class="far fa-times font-20 color-theme cursor-pointer ml-3" v-if="modalColumn.show" @click="modalColumn.show = false"></i>
                                 <i class="far fa-exchange-alt font-20 color-theme cursor-pointer ml-3" v-else @click="modalColumn.show = true"></i>
                              </div>
                              <Picker v-show="modalColumn.show" :data="emojiIndex" :showPreview="false" :showSearch="false" set="twitter" @select="selectEmoji" class="w-100" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="saveColumn">{{ $t("ticketSearch.buttons.save") }}</button>
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("ticketSearch.buttons.close") }}</button>
               </div>
            </div>
         </div>
      </div>
      
      <!-- modalTicket -->
      <div class="modal fade" id="modalTicket" tabindex="-1" aria-labelledby="modalTicketLabel" data-backdrop="static" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalTicketLabel">Ticket</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeTicketMessages"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="chatapp_body">
                     <div class="chat-header">
                        <a href="javascript:;" class="open_detail">
                           <div class="media align-items-center mb-0">
                              <img class="rounded-circle w35" :src="modal.photo == null ? '' : modal.photo" @error="imageError" alt="Avatar">
                              <div class="media-body mr-3 ml-3">
                                 <h6 class="my-0">{{ modal.name == null ? 'Unknown' : modal.name }}</h6>
                                 <small class="row overflow-hidden h20"><role v-for="(role, index) in modal.roles" :key="index" :role="role" /></small>
                              </div>
                           </div>
                        </a>
                        <div v-if="modal.ticket != null">
                           <a href="javascript:;" @click="closeTicket" v-if="modal.ticket.status != 'Closed'" class="btn btn-sm btn-default hidden-xs mr-1"><i class="fal fa-check mr-1"></i> Complete</a>
                           <a href="javascript:;" @click="deleteTicket" class="btn btn-sm btn-default hidden-xs"><i class="fal fa-trash font-13 mr-1"></i> Delete</a>
                        </div>
                     </div>
                     <div class="chat-history" :class="modal.attachments.length > 0 ? 'attachments' : ''">
                        <div class="w-100 text-center">
                           <button id="loadMore" class="btn btn-primary" @click="loadMessages" v-if="modal.messages.length % 100 == 0 && modal.messages.length > 0">Load more</button>
                        </div>
                        <ul class="message_data">
                           <message v-for="(message, index) in modal.messages" :key="index" :message="message" />
                        </ul>
                     </div>
                     <div class="chat-message">
                        <div class="d-flex overflow-auto mb-1" v-if="modal.attachments.length > 0">
                           <attachment v-for="(file, index) in modal.attachments" :key="index" :file="file" :index="index" @remove="removeFile($event)" />
                        </div>
                        <div class="form-group c_form_group position-relative m-0 p-0" :class="modal.attachments.length > 0 ? 'attachments' : ''">
                           <a href="javascript:;" class="icon-file">
                              <i class="far fa-file"></i>
                              <input class="h-100 w-100" type="file" name="file" multiple ref="files" @change="showFiles" />
                           </a>
                           <TextAreaDeck position_emoji="top" height="65" :model="modal.message" :placeholder="'Enter text here...'" @attValor="modal.message = $event;" @enterPress="sendMessage" />
                           <!-- <textarea type="text" ref="textarea" class="form-control" placeholder="Enter text here..." v-model="modal.message" @keyup.enter="sendMessage"></textarea> -->
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal" @click="closeTicketMessages">Close</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import draggable from "vuedraggable"
import role from '@/components/ticket/Role.vue'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import ticket from '@/components/ticket/Ticket.vue'
import message from '@/components/ticket/Message.vue'
import TextAreaDeck from '@/components/TextAreaDeck.vue'
import attachment from '@/components/ticket/Attachment.vue'

import "emoji-mart-vue-fast/css/emoji-mart.css"
import data from "emoji-mart-vue-fast/data/all.json"
import { Picker, EmojiIndex } from "emoji-mart-vue-fast"

let emojiIndex = new EmojiIndex(data);

export default {
	name: 'TicketSearch',
   data: function () {
      return {
         ptBR: ptBR,
         emojiIndex: emojiIndex,
         pesquisa: {'tipo': 'Status', 'discord': null, 'status': this.$t('ticketSearch.search.statusList')[0], 'dataI': null, 'dataF': null, 'resultado': []},
         webSocket: null,
         modal: {'messages': [], 'name': null, 'photo': null, 'roles': [], 'message': '', 'attachments': [], 'ticket': null},
         modalColumn: {'id': null, 'name': null, 'color': '#ff7321', 'tickets': [], 'idServer': null, 'icon': '🧾', 'show': false}
      }
   },
   computed: {
      ... mapState({
         urlSocketTicket: state => state.urlSocketTicket,
         dadosUsuario: state => state.dadosUsuario,
         serverS: state => state.serverS,
         urlRest: state => state.urlRest
      })
   },
   components: {
      ticket, message, role, attachment, TextAreaDeck, draggable, Picker
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      selectEmoji : function (emoji) {
         this.modalColumn.icon = emoji.native
      },
      toggleDropdown : function () {
         $(".header-dropdown li .dropdown-menu").toggleClass("vivify fadeIn");
      },
      attColumn : function (event) {
         if (this.webSocket != null) {
            this.webSocket.send(JSON.stringify({'type': "attTicketColumn", 'value': {'idTicket': event.clone.id, 'idColumn': event.to.id }}));
            
         } else {
            this.startWsTicket()
         }
      },
      openAddColumn : function () {
         this.modalColumn = {'id': null, 'name': null, 'color': '#ff7321', 'tickets': [], 'idServer': this.serverS.id_s, 'icon': '🧾', 'show': false}
         $("#modalColumn").modal("show")
      },
      openEditColumn : function (coluna) {
         let column = JSON.parse(JSON.stringify(coluna))
         this.modalColumn = {'id': column.id, 'name': column.name, 'color': column.color, 'tickets': [], 'idServer': column.idServer, 'icon': column.icon, 'show': false}

         $("#modalColumn").modal("show")
      },
      saveColumn : function () {
         if (this.webSocket != null) {
            if (this.modalColumn.name == null || String(this.modalColumn.name).trim().length == 0) {
               this.$toast.fire({
                  icon: 'error',
                  title: this.$t("serverSettings.swal.invName")
               })

               return
            } else if (this.modalColumn.color == null || String(this.modalColumn.color).trim().length == 0) {
               this.$toast.fire({
                  icon: 'error',
                  title: this.$t("serverSettings.swal.invColor")
               })

               return
            }

            this.webSocket.send(JSON.stringify({'type': "saveColumn", 'value': this.modalColumn }));
            this.getTickets()
            
            $("#modalColumn").modal("hide")

            this.$toast.fire({
               icon: 'success',
               title: this.$t("serverSettings.swal.ticket.saved")
            })
         } else {
            this.startWsTicket()
         }
      },
      deleteColumn : function (idColuna) {
         if (this.webSocket != null) {
            Swal.fire({
               icon: 'warning',
               title: this.$t("serverSettings.swal.ticket.questDeleted"),
               confirmButtonText: this.$t('serverSettings.swal.btnConfirm'),
               showCancelButton: true,
               cancelButtonText: this.$t('serverSettings.swal.btnCancel')

            }).then((result) => {
               if (result.isConfirmed) {
                  this.webSocket.send(JSON.stringify({'type': "deleteColumn", 'value': idColuna }));
                  this.getTickets()
                  
                  this.$toast.fire({
                     icon: 'success',
                     title: this.$t("serverSettings.swal.ticket.deletedCol")
                  })
               }
            })
         } else {
            this.startWsTicket()
         }
      },
      startWsTicket : function () {
         this.webSocket = new WebSocket(this.urlSocketTicket);

         this.webSocket.onmessage = async (event) => {
            let body = JSON.parse(event.data);
            let messageIndex;

            switch (body.type) {
               case "notifyMessage":
                  var colunaIndex = this.pesquisa.resultado.findIndex(c => c.id == body.idColumn)

                  if (colunaIndex != -1) {
                     var coluna = this.pesquisa.resultado[colunaIndex]
                     var ticketIndex = coluna.tickets.findIndex(t => t.idChannel == body.idChannel)
   
                     if (ticketIndex != -1) {
                        if (coluna.tickets[ticketIndex].status == 'Closed') {
                           this.getTickets()
      
                        } else {
                           coluna.tickets[ticketIndex].newMessage = body.value;
                           this.$set(this.pesquisa.resultado, colunaIndex, coluna)
                        }
                     } else {
                        this.getTickets()
                     }
                  } else {
                     this.getTickets()
                  }
               break;
               case "deleteMessage":
                  messageIndex = this.modal.messages.findIndex(msg => msg.id == body.message.id)

                  if (messageIndex != -1) {
                     this.modal.messages.splice(messageIndex, 1)
                  }
               break;
               case "updateMessage":
                  messageIndex = this.modal.messages.findIndex(msg => msg.id == body.message.id)

                  if (messageIndex != -1) {
                     this.$set(this.modal.messages, messageIndex, body.message)
                  }
               break;
               case "loadMessages":
                  var position1 = document.querySelector(".chat-history").scrollHeight
                  this.modal.messages = body.messages;

                  setTimeout(() => {
                     var position2 = document.querySelector(".chat-history").scrollHeight
                     $(".chat-history").animate({scrollTop: position2 - position1}, 100);
                     setTimeout(() => this.$store.dispatch('alternarTelaCarregamento', false), 75);
                  }, 300);
               break;
               case "sendMessage":
                  this.modal.messages.push(body.message);
                  setTimeout(() => $(".chat-history").animate({scrollTop: document.querySelector(".chat-history").scrollHeight}, 100), 300);

               break;
               case "viewTicket":
                  this.modal.messages = body.messages;
                  this.modal.name = body.name;
                  this.modal.photo = body.photo;
                  this.modal.roles = body.roles;

                  $("#modalTicket").modal("show")

                  setTimeout(() => {
                     // this.$refs.textarea.focus()
                     $('textarea').focus()
                     $(".chat-history").animate({scrollTop: document.querySelector(".chat-history").scrollHeight}, 100)
                     setTimeout(() => this.$store.dispatch('alternarTelaCarregamento', false), 75);
                  }, 300);
               break;
               case "search":
                  this.pesquisa.resultado = [];
                  this.pesquisa.resultado = body.result;
                  this.$store.dispatch('alternarTelaCarregamento', false)
               break;
            }
         }
         
         this.webSocket.onopen = () => {
            this.getTickets()
         }

         this.webSocket.onclose = () => {
            this.$store.dispatch('alternarTelaCarregamento', false)
            this.webSocket = null;
            $("#modalTicket").modal("hide")
         }

         this.webSocket.onerror = () => {
            $("#modalTicket").modal("hide")
            this.$store.dispatch('alternarTelaCarregamento', false)

            // this.$toast.fire({
            //    icon: 'error',
            //    title: 'Connection error'
            // })
         }
      },
      getTickets : function () {
         if (this.webSocket != null) {
            let valor = '';

            switch (this.pesquisa.tipo) {
               case 'Discord':
                  valor = this.pesquisa.discord
               break;
               default:
                  valor = this.pesquisa.status.key
               break;
            }

            this.$store.dispatch('alternarTelaCarregamento', true)
            this.webSocket.send(JSON.stringify({
               type: "search",
               value: {
                  idServer: this.serverS.id_s,
                  type: this.pesquisa.tipo,
                  value: valor,
                  date1: this.pesquisa.dataI,
                  date2: this.pesquisa.dataF
               }
            }));
         } else {
            this.startWsTicket()
         }
      },
      closeTicketMessages : function () {
         if (this.webSocket != null) {
            this.webSocket.send(JSON.stringify({'type': "closeTicketMessages" }));
         } else {
            this.startWsTicket()
         }
      },
      viewTicket : function (ticket) {
         if (this.webSocket != null) {
            this.$store.dispatch('alternarTelaCarregamento', true)
            this.modal = {'messages': [], 'name': null, 'photo': null, 'roles': [], 'message': '', 'attachments': [], 'ticket': ticket}
            this.webSocket.send(JSON.stringify({'type': "viewTicket", 'value': ticket.idChannel }));
         } else {
            this.startWsTicket()
         }
      },
      sendMessage : function () {
         if (this.modal.attachments.length > 0) {
            this.sendFiles()
         } else {
            if (this.webSocket != null) {
               this.webSocket.send(JSON.stringify({'type': "sendMessage", 'value': String(this.modal.message).replace('\n', ''), 'idUser': this.dadosUsuario.id }));
            } else {
               this.startWsTicket()
            }

            this.modal.message = '';
            this.modal.attachments = [];
         }
      },
      loadMessages : function () {
         if (this.webSocket != null) {
            this.$store.dispatch('alternarTelaCarregamento', true)
            this.webSocket.send(JSON.stringify({'type': "loadMessages", 'value': this.modal.messages.length }));
         } else {
            this.startWsTicket()
         }
      },
      deleteTicket : function () {
         if (this.webSocket != null) {
            this.webSocket.send(JSON.stringify({'type': "deleteTicket", 'value': this.modal.ticket.idChannel }));
            $("#modalTicket").modal("hide")

            var colunaIndex = this.pesquisa.resultado.findIndex(c => c.id == this.modal.ticket.idColumn)

            if (colunaIndex != -1) {
               var coluna = this.pesquisa.resultado[colunaIndex]
               var ticketIndex = coluna.tickets.findIndex(t => t.idChannel == this.modal.ticket.idChannel)

               if (ticketIndex != -1) {
                  coluna.tickets.splice(ticketIndex, 1)
                  this.$set(this.pesquisa.resultado, colunaIndex, coluna)
                  
               } else {
                  this.getTickets()
               }
            } else {
               this.getTickets()
            }

            this.$toast.fire({
               icon: 'success',
               title: this.$t("serverSettings.swal.ticket.deletedTicket")
            })
         } else {
            this.startWsTicket()
         }
      },
      closeTicket : function () {
         if (this.webSocket != null) {
            this.webSocket.send(JSON.stringify({'type': "closeTicket", 'value': this.modal.ticket.idChannel }));
            this.getTickets()

            $("#modalTicket").modal("hide")

            this.$toast.fire({
               icon: 'success',
               title: this.$t("serverSettings.swal.ticket.closedTicket")
            })
         } else {
            this.startWsTicket()
         }
      },
      sendFiles : function () {
         let dataForm = new FormData();
         dataForm.append('info', JSON.stringify({'idTicket': this.modal.ticket.idChannel, 'message': this.modal.message, 'idUser': this.dadosUsuario.id }));

         for (let file of this.modal.attachments) {
            dataForm.append('file', file, file.name.replace(/[^\w\s.]/gi, ''));
         }

         let processingMsg = {
            "idUser": Math.floor(Math.random() * 9999999999999999),
            "date": new Date(),
            "emojis": [],
            "attachs": [],
            "author": "System",
            "photo": this.dadosUsuario.usuarioFoto,
            "message": "Processing files..."
         }

         this.modal.messages.push(processingMsg)
         this.modal.message = '';
         this.modal.attachments = [];

         setTimeout(() => $(".chat-history").animate({scrollTop: document.querySelector(".chat-history").scrollHeight}, 100), 250);

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/sendFiles',
            headers: {
					'Content-Type': 'multipart/form-data'
            },
				data: dataForm

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.modal.messages.splice(this.modal.messages.map(msg => msg.idUser).indexOf(processingMsg.idUser), 1)
         })
      },
      showFiles : function () {
         if ((this.$refs.files.files.length + this.modal.attachments.length) > 10) {
            Swal.fire({
               icon: 'error',
               title: this.$t("serverSettings.swal.limit"),
               text: this.$t("serverSettings.swal.attachsLimit")
            });

            this.$refs.files.value = "";

            return
         }

         for (let file of this.$refs.files.files) {
            if (file.size < 26214400) {
               this.modal.attachments.push(file)
            } else {
               Swal.fire({
                  icon: 'error',
                  title: this.$t("serverSettings.swal.sizeExcedd"),
                  text: this.$t("serverSettings.swal.fileSizeExcedd")
               });

               break
            }
         }

         setTimeout(() => {
            this.$refs.files.value = "";
            $('textarea').focus()
            // this.$refs.textarea.focus()
         }, 250);
      },
      removeFile : function (index) {
         this.modal.attachments.splice(index, 1)
      },
      calcularData : function () {
         let dataI = new Date();
         let dataF = new Date();
         dataI.setDate(dataI.getDate() - 7);

         if (window.innerWidth < 767) {
            let zeroFill = n => {
               return ('0' + n).slice(-2);
            }

            this.pesquisa.dataI = dataI.getFullYear() +'-'+ zeroFill((dataI.getMonth() + 1)) +'-'+ zeroFill(dataI.getDate());
            this.pesquisa.dataF = dataF.getFullYear() +'-'+ zeroFill((dataF.getMonth() + 1)) +'-'+ zeroFill(dataF.getDate());

         } else {
            this.pesquisa.dataI = dataI;
            this.pesquisa.dataF = dataF;
         }
      }
   },
   mounted() {
      this.calcularData()
      this.startWsTicket()

      $(window).resize(() => {
         this.calcularData()
      });

      $('#search-nav .dropdown-menu').on('click', function(e) {
         if (e.target.nodeName != 'BUTTON') {
            e.stopPropagation();
         }
      });
   },
   beforeDestroy() {
      if (this.webSocket != null) {
         this.webSocket.close()
         this.webSocket = null
      }
   }
}

</script>

<style scoped>

input[type="file"] {
   position: absolute;
   top: 0;
   right: 0;
   min-width: 100%;
   min-height: 100%;
   font-size: 100px;
   text-align: right;
   filter: alpha(opacity=0);
   opacity: 0;
   outline: none;
   cursor: inherit;
   display: block;
}

.card-drop {
   width: 330px;
   min-width: 330px;
   position: relative;
}

.card-drop > .card {
   min-height: 150px;
}

.card-drop .row-drop {
   min-height: 80px;
}

.card-drop .empty::after {
   color: var(--text-secondary);
   content: 'No ticket found';
   display: block;
   text-align: center;
   position: absolute;
   bottom: 40px;
   width: 100%;
}

.card .header .header-dropdown {
   top: 0px;
   right: 0px;
}

.card .header .header-dropdown li a.dropdown-toggle:after {
   font-weight: 400;
   content: '\f141';
}

.carousel {
   display: flex;
   overflow: auto;
   width: 100%;
   height: calc(100vh - 245px);
}

@media (max-width: 992px) {
   .carousel {
      height: calc(100vh - 135px);
   }
}

</style>