<template>
   <div class="w-max-content position-relative">
      <div v-if="extension == 'png' || extension == 'jpg' || extension == 'jpeg' || extension == 'ico' || extension == 'gif' || extension == 'webp'" class="img h75 mb-2">
         <img title="Open" :src="url" @click="openLink">
      </div>

      <div class="audio-player mb-2" v-else-if="extension == 'mp3' || extension == 'ogg' || extension == 'wav'">
         <div ref="playBtn" id="play-btn" title="Play/Pause"></div>
         <div class="audio-wrapper" ref="playerContainer" id="player-container" href="javascript:;">
            <audio ref="player" id="player" @timeupdate="initProgressBar()">
               <source :src="url" type="audio/ogg">
               <source :src="url" type="audio/wav">
               <source :src="url" type="audio/mpeg">
            </audio>
         </div>
         <div class="player-controls scrubber">
            <p class="limitador"><i class="far fa-external-link mx-2" title="Open" @click="openLink"></i>{{ url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.')) }}</p>
            <span id="seekObjContainer">
               <progress ref="seekObj" id="seekObj" value="0" max="1"></progress>
            </span>
            <br>
            <small class="float-left start-time" style="left: 10px;" ref="startTime"></small>
            <small class="float-right end-time" style="right: 10px;" ref="endTime"></small>
         </div>
      </div>

      <div v-else-if="extension == 'mp4' || extension == 'webm'" class="video h75 mb-2">
         <video title="Open" @click="openLink">
            <source :src="url" type="video/mp4">
            <source :src="url" type="video/webm">
         </video>
      </div>

      <div v-else class="alert alert-primary text-center w200 p-y-10 mb-2" @click="openLink">
         <i class="far fa-external-link" title="Open"></i><span class="limitador">{{ url.substring(url.lastIndexOf('/') + 1) }}</span>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'

export default {
	name: 'MediaFromUrl',
   props: ['url'],
   data: function () {
      return {
         extension: ''
      }
   },
   methods: {
      openLink : function () {
         window.open(this.url)
		},
      calculateTotalValue : function(length) {
         var minutes = Math.floor(length / 60),
         seconds_int = length - minutes * 60,
         seconds_str = seconds_int.toString(),
         seconds = seconds_str.substr(0, 2),
         time = minutes + ':' + seconds

         return time;
      },
      calculateCurrentValue : function(currentTime) {
         var current_minute = parseInt(currentTime / 60) % 60,
         current_seconds_long = currentTime % 60,
         current_seconds = current_seconds_long.toFixed(),
         current_time = (current_minute < 10 ? "0" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds);

         return current_time;
      },
      initProgressBar : function() {
         var player = this.$refs.player;
         var length = player.duration;
         var current_time = player.currentTime;

         var totalLength = this.calculateTotalValue(length)
         $(this.$refs.endTime).html(totalLength);

         var currentTime = this.calculateCurrentValue(current_time);
         $(this.$refs.startTime).html(currentTime);

         var progressbar = this.$refs.seekObj;
         progressbar.value = (player.currentTime / player.duration);
         progressbar.addEventListener("click", seek);

         if (player.currentTime == player.duration) {
            $(this.$refs.playBtn).removeClass('pause');
         }

         function seek(evt) {
            var percent = evt.offsetX / this.offsetWidth;
            player.currentTime = percent * player.duration;
            progressbar.value = percent / 100;
         }
      },
      initPlayers : function(num) {
         let ref = this;
         
         for (var i = 0; i < num; i++) {
            $(function() {
               var player = ref.$refs.player,
               playBtn = ref.$refs.playBtn;

               if (playBtn != null) {
                  playBtn.addEventListener('click', function() {
                     togglePlay()
                  });
               }

               function togglePlay() {
                  if (player.paused === false) {
                     player.pause();
                     // isPlaying = false;
                     $(ref.$refs.playBtn).removeClass('pause');

                  } else {
                     player.play();
                     $(ref.$refs.playBtn).addClass('pause');
                     // isPlaying = true;
                  }
               }
            });
         }
      }
   },
   mounted() {
      this.extension = String(this.url).split('.').pop();

      if (this.extension == 'mp3' || this.extension == 'ogg' || this.extension == 'wav') {
         setTimeout(() => {
            this.initPlayers($(this.$refs.playerContainer).length);
            
            setTimeout(() => {
               this.initProgressBar()
            }, 1000);
         }, 100);
      }
   }
}

</script>

<style scoped>

video,
img {
   object-fit: cover;
   border-radius: .25rem;
   height: 100%;
}

.img,
.video {
   max-width: 200px !important;
   cursor: pointer;
}

.video::after {
   content: "\f04b";
   font-family: "Font Awesome 5 Pro";
   font-weight: 400;
   position: absolute;
   top: 6px;
   left: 12px;
   text-shadow: 0 2px 0 var(--body-color);
}

.img:hover::before,
.video:hover::before,
.video:hover::after {
   content: "";
}

.video::before,
.img::before {
   content: "\f08e";
   font-family: "Font Awesome 5 Pro";
   font-weight: 400;
   position: absolute;
   bottom: 12px;
   right: 12px;
   text-shadow: 0 2px 0 var(--body-color);
}

</style>